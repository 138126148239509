<template>
<div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
        <vx-card>
            <div slot="no-body" class="full-page-bg-color">
                <div class="vx-row">
                    <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                        <img src="@/assets/images/logo.png" alt="login" class="mx-auto">
                    </div>
                    <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                        <div class="p-8 mt-base">
                            <div class="vx-card__title mb-5">
                                <h4 class="mb-4 flex justify-center">BOAT HARBOUR PARK </h4>
                                <h6 class="flex justify-center">Ballot Open</h6>
                            </div>
                            <vs-divider class="mb-0" />
                            <div class="vx-card__body">
                                <h6 class="mb-4 flex justify-center">Ballot Date</h6>
                                <span class="mb-5 flex justify-center">Sat, 29 May 2021</span>

                                <h6 class="mb-4 flex justify-center">Capacity</h6>
                                <span class="flex justify-center">1000</span>
                            </div>
                            <vs-divider class="mb-0" />
                            <div class="flex mb-4">
                                <div class="flex-1">
                                    <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-10">
                                        <vs-button color="success" @click="optIn">Opt In</vs-button>
                                        <vs-button color="danger" @click="optOut">Opt Out</vs-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</div>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    computed: {},
    created() {},
    methods: {
        optIn() {
          this.$vs.notify({
                title: "",
                text: "Ballot Opt In",
                color: "success",
                position: "top-center"
            });
        },
        optOut() {
          this.$vs.notify({
                title: "",
                text: "Ballot Opt Out",
                color: "warning",
                position: "top-center"
            });
        },
    }
}
</script>
